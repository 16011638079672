// --- for IE !! ---
// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
/*
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength,padString) {
        targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
            return String(this);
        }
        else {
            targetLength = targetLength-this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0,targetLength) + String(this);
        }
    };
}
// --- END for IE !! ---


jQuery('._row._checkbox-radio span').remove();
jQuery('._row._checkbox-radio').append('Autorizzo il trattamento dei dati secondo la <a target="_blank" href="https://www.iubenda.com/privacy-policy/14998928">privacy policy</a>*');
var url = window.location.href;
jQuery('input[data-name=paginadicontatto]').attr('value',url);
*/


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

var nowDate = dd + '/' + mm + '/' +  yyyy;
jQuery('input[data-name=data_contatto]').attr('value',nowDate);

var nowTime = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');
jQuery('._form_element input[data-name=ora_contatto]').attr('value',nowTime);


var allForms = jQuery('form._form');
allForms.each(function(index) {
  var formId = jQuery(this).children('input[name=f]').attr('value');
  jQuery(this).find('input[data-name=formid]').attr('value',formId);
});

var pageUrl = window.location.href;
jQuery('._form_element input[data-name=pagina_contatto]').attr('value',pageUrl);
/*
_iub.cons_instructions.push(["load",
{
    submitElement: document.getElementById("_form_93_submit"), // if this line is missing, the consent is not automatically recorded at submit time; a call to _iub.cons.sendData (see section below) is needed instead
    
    form: {
        selector: document.getElementById("_form_93_"), // The selector of the form from which you'd like to detect the data
        
      },
    
}
])*/
$(document).ready(function() {

	// SMOOTH SCROLL
	$('a[href*="#contacts"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});



	// BACK TO TOP

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};

});